<template>
  <Wrapper>
    <div class="service">

      <DetailBanner titleType='2' title=""></DetailBanner>

      <water-title Chinese="供求信息" English="SUPPLY AND DEMAND"></water-title>

      <div class="w">
        <div style="width: 100%;margin-top: 63px;">
          <div class="switch_tab">
            <div :class="['item',actIndex == 0?'active':'']" @click="switchChange(0)">
              供应信息
            </div>
            <div :class="['item',actIndex == 1?'active':'']" @click="switchChange(1)">
              求购信息
            </div>
          </div>
        </div>
        <div class="input-box" style="margin-top: 53px;">
          <span>
            品种:<el-input clearable v-model="searchParams.name" style="width:180px;margin-right:20px;margin-left: 5px;" placeholder="请输入内容"></el-input>
          </span>
          <span>
            产地:<el-input clearable v-model="searchParams.addr" style="width:180px;margin-right:20px;margin-left: 5px;" placeholder="请输入内容"></el-input>
          </span>
          <span v-if="actIndex == 0">
            规格:<el-input v-model="searchParams.spec" style="width:180px;margin-right:20px;margin-left: 5px;" placeholder="请输入内容"></el-input>
          </span>
          <span v-else>
            类型:
            <el-select v-model="searchParams.type" style="width:180px;margin-right:20px;margin-left: 5px;" placeholder="请选择" clearable>
              <el-option label="个人求购" :value="1"/>
              <el-option label="企业求购" :value="2"/>
            </el-select>
          </span>
          <el-button style="margin-left:auto" icon="el-icon-refresh-left" @click="refresh">重置</el-button>
          <el-button icon="el-icon-search" class="search-btn" @click="search">搜索</el-button>
        </div>
        <classify v-if="actIndex == 0" style="padding: 0px 20px;border-bottom: 0px !important;" cname="排序:" :actId="actId1" @selelctId="selelcted1" :forArr="orderList" />
        <classify v-else style="padding: 0px 20px;border-bottom: 0px !important;" cname="排序:" :actId="actId2" @selelctId="selelcted2" :forArr="timeTypeList" />
        <img class="hr" src="@/assets/imgs/hr.png" />
        <div class="list_content">
          <div class="left_wrap">
            <div class="left_item" v-for="(item,index) in leftList" :key="index">
              <div class="img_wrap">
                <el-image
                  style="width: 100%;height: 100%;"
                  :fit="'cover'"
                  :src="baseUrl + item.picsurl"
                  lazy
                ></el-image>
              </div>
              <div class="desc_wrap">
                <div class="title">
                  品名 {{item.name}}
                </div>
                <div class="middle_content">
                  <div class="item">
                    <div class="title_left">规格:</div> 
                    <div class="txt">{{item.spec}}</div>
                  </div>
                  <div class="item">
                    <div class="title_left">单价:</div> 
                    <div class="txt">{{item.price}}</div>
                  </div>
                  <div class="item">
                    <div class="title_left">数量:</div> 
                    <div class="txt">{{item.stockcount + item.unit}}</div>
                  </div>
                </div>
                <div class="middle_content">
                  <div class="item">
                    <div class="title_left">产地:</div> 
                    <div class="txt">{{item.addr}}</div>
                  </div>
                  <div class="item">
                    <div class="title_left">所在地:</div> 
                    <div class="txt">{{item.addr}}</div>
                  </div>
                  <div class="item">
                    <div class="title_left">发布:</div> 
                    <div class="txt">{{item.publishdatetime.split(' ')[0]}}</div>
                  </div>
                </div>
                <div class="middle_content">
                  <div class="item">
                    <div class="title_left">有效期:</div> 
                    <div class="txt">剩余{{ item.deadline }}天</div>
                  </div>
                  <div class="item">
                    <div class="title_left">实名认证:</div> 
                    <div class="txt identify" style="color: white; background-color: #7ACA5A;border-radius: 5px;">已认证</div>
                  </div>
                  <div class="item">
                    <div class="title_left">联系人:</div> 
                    <div class="txt">{{item.contacter}}</div>
                  </div>
                </div>
                <div class="middle_content">
                  <div class="item">
                    <div class="title_left">手机:</div> 
                    <div class="txt">{{item.contacttel}}</div>
                  </div>
                  <div class="item" style="width: 67% !important;">
                    <div class="title_left">地址:</div> 
                    <div class="txt">{{item.deliveryaddr}}</div>
                  </div>
                </div>
                <el-button size="small" style="position: absolute;bottom: 0px; left: 30px;" class="search-btn" @click="goToDetail(item)">查看详情</el-button>
              </div>
            </div>
            <div style="text-align: right;margin-right: 85px;">
              <el-pagination background layout="prev, pager, next, jumper" :total="total"
                @current-change="onChangePage">
              </el-pagination>
            </div>
          </div>
          <div class="right_wrap">
            <div class="item_wrap">
              <div class="title">
                药品排行榜
              </div>
              <div class="line_wrap">
                <div class="innerline"/>
              </div>
              <div class="list_wrap">
                <div class="item" v-for="(item,index) in drugList1" :key="index">
                  <div class="left">
                    <div :class="['num',index < 3 ? 'active_num':'']">
                      <span>{{index + 1}}</span>
                    </div>
                    <span class="arrow">></span>
                  </div>
                  <div class="right">
                    <div class="txt">
                      {{item.name0}}
                    </div>
                    <div class="txt">
                      {{item.name1}}
                    </div>
                    <div class="txt">
                      {{item.name2}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item_wrap">
              <div class="title">
                店铺排行
              </div>
              <div class="line_wrap">
                <div class="innerline"/>
              </div>
              <div class="img_list_wrap">
                <div class="img_item" v-for="(item,index) in drugList2" :key="index">
                  <div class="img">
                    <img src="@/assets/zwt.jpg" alt="" style=" width: 70px;">
                   
                  </div>
                  <div class="desc">
                    <div class="title_desc">
                      {{item.company_name}}
                    </div>
                    <div class="time_desc">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from '../sections/DetailBanner.vue'
import WaterTitle from '@/components/WaterTitle.vue'
import Wrapper from '@/components/Wrapper.vue'
import classify from './serviceComponents/classify.vue'
import { INFO_URL } from '@/config/globalConfig'
export default {
  mixins: [],
  components: {
    Wrapper,
    DetailBanner,
    WaterTitle,
    classify
  },
  data() {
    return {
      baseUrl: INFO_URL,
      actIndex: 0,
      searchParams:{
        name: '',
        addr: '',
        spec: '',
        type:'',
        page: 1,
        pageSize: 4
      },
      actId1:'',
      actId2:'',
      orderList:[
        {name:'急售',id:'0'},
        {name:'推荐',id:'1'},
        {name:'质检',id:'2'},
        {name:'数量',id:'3'},
        {name:'价格',id:'4'},
      ],
      timeTypeList:[
        {name:'按剩余时间',id:'0'},
        {name:'按最新输入',id:'1'},
        {name:'按数量排序',id:'2'},
      ],
      leftList:[],
      drugList1:[],
      drugList2:[],
      total:1
    }
  },
  created() {
    const activeTab = this.$route.query.activeTab
    if(activeTab) {
      this.actIndex = activeTab
    } else {
      this.actIndex = 0
    }
    this.scrollTop()

    this.$route.params
    // mname=龟甲胶&mspec=老君堂&maddr=河南
    let {  mname,mspec,maddr } = this.$route.query
    this.searchParams.name = mname
    this.searchParams.addr = maddr
    this.searchParams.spec = mspec
    this.getYshSupplyList()
    this.getHotMaterial()
    this.getHotShop()
  },
  methods: {
    getHotShop(){
      this.drugList2 = []
      this.$axios.getHotShop().then(res => {
        this.drugList2 = res.data || []
      })
    },
    getHotMaterial(){
      this.$axios.getHotMaterial().then(res => {
        this.drugList1 = []
        let obj = {}
        debugger
        (res.data || []).forEach((element,index) => {
          let num = index % 3
          if (num == 0) {
            obj.name0 = element.name
            if(res.data.length -1 == index){
              this.drugList1.push(JSON.parse(JSON.stringify(obj)))
            }
          } else if (num == 1) {
            obj.name1 = element.name
            if(res.data.length -1 == index){
              this.drugList1.push(JSON.parse(JSON.stringify(obj)))
            }
          } else if (num == 2) {
            obj.name2 = element.name
            this.drugList1.push(JSON.parse(JSON.stringify(obj)))
          }
        });
      })
    },
    getYshSupplyList(){
      const formData = new FormData()
      let { name , addr, spec, type, page, pageSize } = this.searchParams
      formData.append("name", name || '')
      formData.append("addr", addr || '')
      formData.append("page", page || '')
      formData.append("pageSize", pageSize)
      if (this.actIndex == 0) {
        if(this.actId1 === '0'){
          formData.append("mtop", 1)
        }else if (this.actId1 === '1') {
          formData.append("mtop", 2)
        }else if (this.actId1 === '2') {
          formData.append("qualityState", 1)
        }else if (this.actId1 === '3') {
          formData.append("stockcount", 1)
        }else if (this.actId1 === '4'){
          formData.append("price", 1)
        }
        formData.append("spec", spec || '')
        this.$axios.getYshSupplyList(formData).then(res => {
          this.leftList = res.data.data || []
          this.leftList.forEach( lItem => {
            lItem.detailType = 1
          })
          this.total = res.data.total || 0
          console.log('getYshSupplyList:',this.leftList);
        })
      } else {
        if(this.actId2 === '0'){
          formData.append("order", 1)
        }else if (this.actId2 === '1') {
          formData.append("order", 0)
        }else if (this.actId2 === '2') {
          formData.append("stockcount", 1)
        }
        formData.append("type", type || '')
        this.$axios.getPleaseBuy(formData).then(res => {
          this.leftList = res.data.data || []
          this.leftList.forEach( lItem => {
            lItem.stockcount = lItem.buycount
            lItem.picsurl = lItem.pics
            lItem.detailType = 2
          })
          this.total = res.data.total || 0
        })
      }
    },
    scrollTop() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop)
      if (scrollTop === 0) {
        return false
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    switchChange(type){
      this.actIndex = type
      // if (type == 1) {
      //   this.searchParams.name = ''
      //   this.searchParams.addr = ''
      // } else {
      //   let {  mname,mspec,maddr } = this.$route.query
      //   this.searchParams.name = mname
      //   this.searchParams.addr = maddr
      //   this.searchParams.spec = mspec
      // }
      this.getYshSupplyList()
    },
    search(){
      this.searchParams.page = 1
      this.getYshSupplyList()
    },
    refresh(){
      this.searchParams ={
        name: '',
        addr: '',
        spec: '',
        type:'',
        page: 1,
        pageSize: 4
      }
      this.getYshSupplyList()
    },
    goToDetail(row){
      this.$router.push({name:'SupplyDemandDetail',params:{id: row.id, type: row.detailType}})
    },
    selelcted1(item){
      if ( this.actId1 === String(item.id)) {
        this.actId1 = ''
      }else{
        this.actId1 =String(item.id)
      }
      this.searchParams.page = 1
      this.getYshSupplyList()
    },
    selelcted2(item){
      if ( this.actId2 === String(item.id)) {
        this.actId2 = ''
      }else{
        this.actId2 =String(item.id)
      }
      this.searchParams.page = 1
      this.getYshSupplyList()
    },
    onChangePage(page){
      this.searchParams.page = page
      this.getYshSupplyList()
    }
  }
}
</script>

<style lang='scss' scoped>
.service {
  padding: 50px 0px 0px 0px;
}
.switch_tab{
  
  width: 860px;
  height: 40px;
  margin: auto;
  display: flex;
  .item{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #656766;
    background: #C9C9C9;
    font-size: 16px;
    cursor: pointer;
  }
  .active{
    color: white;
    background: #0E36A7 !important;
  }
}
.input-box{
  margin:10px auto;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
  border-radius: 0px;
  display:flex;
  align-items:center;
  height:77px;
  padding:0 20px
}
.search-btn{
  background-color: #315CCF !important;
  color:#fff
}
.hr {
  width: 100%;
  height: 4px;
}
.list_content{
  width: 100%;
  display: flex;
  margin-top: 42px;
  margin-bottom: 150px;
  .left_wrap{
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    .left_item{
      height: 215px;
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 30px;
      .img_wrap{
        width: 215px;
        height: 100%;
        background: #656766;
      }
      .desc_wrap{
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        position: relative;
        .title{
          font-size: 22px;
          color: #2F3542;
        }
        .middle_content{
          width: 100%;
          display: flex;
          padding-top: 15px;
          .item{
            width: 33%;
            font-size: 16px;
            display: flex;
            
            .title_left{
              white-space: nowrap;
              color: #0D162A;
            }
            .txt{
              white-space: nowrap;
              color:#626F90
            }
            .identify{
              color: white;
              background-color: rgb(122, 202, 90);
              border-radius: 5px;
              padding: 5px;
              line-height: 10px;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
  .right_wrap{
    width: 300px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .item_wrap{
      height: 506px;
      width: 100%;
      display: flex;
      flex-direction: column;
      .title{
        font-size: 22px;
        color: #0D162A;
        padding: 10px;
      }
      .line_wrap{
        width: 100%;
        height: 2px;
        background-color: #CED5E1;
        margin-bottom: 10px;
        .innerline{
          width: 60%;
          height: 2px;
          background-color: #315CCF;
        }
      }
      .list_wrap{
        flex: 1;
        width: 100%;
        height: 500px;
        overflow: auto;
        .item{
          width: 100%;
          display: flex;
          padding-top: 17px;
          padding-bottom: 17px;
          border-bottom: #E9ECF2 solid 1px;
          .left{
            width: 30px;
            display: flex;
            .num{
              background-color: #CDD2DD;
              border-radius: 3px;
              width: 16px;
              height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 12px;
            }
            .active_num{
              background-color: #315CCF !important;
            }
            .arrow{
              padding-left: 5px;
              font-size: 12px;
              line-height: 16px;
              font-weight: bold;
            }
          }
          .right{
            flex: 1;
            display: flex;
            padding-left: 8px;
            .txt{
              width: 33%;
              white-space: nowrap;
              color: #0D162A;
            }
          }
        }
      }
      .img_list_wrap{
        flex: 1;
        width: 100%;
        height: 500px;
        overflow: auto;
        .img_item{
          width: 100%;
          display: flex;
          height: 70px;
          margin-top: 30px;
          .img{
            height: 70px;
            width: 70px;
            background-color: white;
          }
          .desc{
            flex: 1;
            height: 70px;
            line-height: 70px;
            display: flex;
            flex-direction: column;
            padding-left: 8px;
            justify-content: space-between;
            .title_desc{
              color: #2F3542;
              font-size: 16px;
            }
            .time_desc{
              color: #A4A9B4;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>